import React, { useEffect, useMemo, useRef } from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { Rate } from 'antd';

const CustomersTalkUsContent = () => {
  const { t } = useTranslation();
  const containerRef = useRef(null);

  const dataList = useMemo(() => [
    {
      name: 'Claire',
      desc: t(
        `This website's services are exceptional, providing real-time monitoring and instant notifications for changes. It saves me time by eliminating manual checks and offers detailed change records, keeping me informed on updates. This enhances my efficiency and simplifies information management, allowing me to focus on other important tasks.`
      ),
      rate: 5,
    },
    {
      name: 'Harry',
      desc: t(
        `I find the services provided by this website to be quite good. It instantly notifies me via email about changes to the sites I follow, helped me a lot in my daily work.  I look forward to more service offerings in the future to enhance my overall experience.`
      ),
      rate: 4.5,
    },
    {
      name: 'Zoey',
      desc: t(
        `I really love this website! It instantly notifies me of any changes it monitors, and it's very easy to use and quick to get the hang of. It has greatly improved my work efficiency. It’s just perfect for me, saving me a lot of hassle and allowing me to focus on other tasks.`
      ),
      rate: 5,
    },
    {
      name: 'Lush',
      desc: t(
        `Monitoring feature has made my work so much easier! I no longer need to check manually, and the real-time notifications allow me to focus on other important tasks. I'm extremely satisfied!`
      ),
      rate: 5,
    },
    {
      name: 'Gordan',
      desc: t(
        `I feel that this website performs adequately in monitoring, it effectively eases my workload, but there is still room for improvement.`
      ),
      rate: 4,
    },
  ], [])

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const scrollStep = 1;
    const scrollInterval = 30;

    let isUserScrolling = false;

    const scroll = () => {
      if (!isUserScrolling) {
        container.scrollLeft += scrollStep;
        if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
          container.scrollLeft = 0;
        }
      }
    };

    const handleTouchStart = () => {
      isUserScrolling = true;
    };

    const handleTouchEnd = () => {
      isUserScrolling = false;
    };

    // 添加触摸事件监听器
    container.addEventListener('touchstart', handleTouchStart);
    container.addEventListener('touchend', handleTouchEnd);

    const intervalId = setInterval(scroll, scrollInterval);

    // 清理定时器和事件监听器
    return () => {
      clearInterval(intervalId);
      container.removeEventListener('touchstart', handleTouchStart);
      container.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);

  return (
    <>
      <div className={styles.content}>
        <h2>{t('OurCustomersTalkAboutUs')}</h2>

        <div className={styles.container} ref={containerRef}>
          {dataList.map((item, index) => {
            return (
              <div className={styles.cardContainer} key={index + item.name}>
                <div className={styles.userContainer}>
                  <Image
                    src={`/images/home/customer-default-icon.webp`}
                    alt={item.name}
                    className={styles.icon}
                    width={46}
                    height={46}
                  />
                  <span className={styles.userName}>{item.name}</span>
                </div>
                <Rate className={styles.star} allowHalf disabled defaultValue={item.rate} />
                <div className={styles.desc}>{item.desc}</div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CustomersTalkUsContent;
